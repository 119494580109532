import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

const ListLink = props => (
  <span
    css={css`
      display: inline-block;
      margin-right: 1rem;
    `}
  >
    {
      props.target ? (
        <a
          href={props.to}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.children}
        </a>
      ) : (
        <Link to={props.to}>{props.children}</Link>
      )
    }
  </span>
)

const Nav = () => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-start;
    `}
    >
      {/* <ListLink to="/sitemap.xml">SiteMap</ListLink> */}
      <ListLink to="/links">Links</ListLink>
      <ListLink to="/tools">Tools</ListLink>
    </div>
  )
}

export default Nav
