import React from "react"
import { css } from "@emotion/core"
import FooterNav from './footer-nav'

const Footer = () => {
  return (
    <footer
      css={css`
        min-height: 10vh;
        max-height: 10vh;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
      `}
    >

      <FooterNav />

      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding-bottom: 10px;
        `}
      >
        © 2019 - {new Date().getFullYear()}, Built with

        <span
          style={{
            padding: '0 4px'
          }}
        >
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </span>
      </div>
    </footer>
  )
}

export default Footer
